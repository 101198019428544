import { MouseEvent, memo } from 'react'
import { Palette } from '@typeform/ginger/dist/constants/palettes'
import { TUser } from 'services/user-service/types'
import { TTopMenuItem } from 'models/top-menu-item-model.types'

import {
  MegaMenuNoJsStyles,
  NavigationContainer,
  TopMenuContainer,
} from './mega-menu.styles'
import RightNavigation from './components/right-navigation/right-navigation'
import MegaMenuProvider from './mega-menu-provider'
import {
  TCtaData,
  TMenuItemClickHandler,
  TSubMenuItemClickEventHandler,
  TUserMenuClickEventHandler,
  TUserMenuItemClickEventHandler,
  TUserMenuItems,
} from './mega-menu.types'
import MenuBackground from './components/menu-background/menu-background'
import Logo from './components/logo/logo'
import TopMenu from './components/top-menu/top-menu'

export type TMegaMenuProps = {
  palette: Palette
  transparentBackground: boolean
  mobileMenuTriggerOpenLabel: string
  mobileMenuTriggerCloseLabel: string
  logoTitle: string
  logoHref: string
  menuItems?: TTopMenuItem[]
  highlightedMobileLink?: TCtaData
  userMenuItems?: TUserMenuItems
  isFetchingUser: boolean
  user?: TUser
  ctas: Array<TCtaData>
  isMinimalMenu?: boolean
  onLogoClick?: (event: MouseEvent<HTMLAnchorElement>) => void
  onUserMenuClick?: TUserMenuClickEventHandler
  onUserMenuItemClick?: TUserMenuItemClickEventHandler
  onMenuItemClick?: TMenuItemClickHandler
  onSubMenuItemClick?: TSubMenuItemClickEventHandler
  bannerHeight?: number
  bannerIsFixed?: boolean
  shouldUseNowYouKnowLogo?: boolean
}

const MegaMenu = ({
  palette,
  menuItems,
  userMenuItems,
  isFetchingUser,
  user,
  ctas,
  highlightedMobileLink,
  transparentBackground,
  mobileMenuTriggerOpenLabel,
  mobileMenuTriggerCloseLabel,
  logoTitle,
  logoHref,
  onLogoClick,
  onUserMenuClick,
  onUserMenuItemClick,
  onMenuItemClick,
  onSubMenuItemClick,
  isMinimalMenu,
  bannerHeight,
  bannerIsFixed,
  shouldUseNowYouKnowLogo = false,
}: TMegaMenuProps) => {
  return (
    <>
      <MegaMenuNoJsStyles
        palette={palette}
        transparentBackground={transparentBackground}
      />
      <MegaMenuProvider
        palette={palette}
        transparentBackground={transparentBackground}
        hasUser={!!user}
        hasUserMenuItems={!!userMenuItems}
        isFetchingUser={isFetchingUser}
        onUserMenuClick={onUserMenuClick}
        onUserMenuItemClick={onUserMenuItemClick}
        onMenuItemClick={onMenuItemClick}
        onSubMenuItemClick={onSubMenuItemClick}
      >
        <NavigationContainer
          data-qa='navigation'
          data-testid='mega-menu'
          palette={palette}
          bannerHeight={bannerHeight}
          bannerIsFixed={bannerIsFixed}
        >
          <TopMenuContainer>
            <MenuBackground />
            <Logo
              title={logoTitle}
              href={logoHref}
              onClick={onLogoClick}
              shouldUseNowYouKnowLogo={shouldUseNowYouKnowLogo}
            />
            {!isMinimalMenu && !!menuItems && (
              <TopMenu
                mobileMenuTriggerOpenLabel={mobileMenuTriggerOpenLabel}
                mobileMenuTriggerCloseLabel={mobileMenuTriggerCloseLabel}
                menuItems={menuItems}
                bannerHeight={bannerHeight}
              />
            )}
            <RightNavigation
              isMinimalMenu={isMinimalMenu}
              userMenuItems={userMenuItems}
              user={user}
              ctas={ctas}
              highlightedMobileLink={highlightedMobileLink}
            />
          </TopMenuContainer>
        </NavigationContainer>
      </MegaMenuProvider>
    </>
  )
}

export default memo(MegaMenu)
