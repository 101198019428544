import TypeformLogo, { TLogoProps } from 'components/atoms/common/logo'
import dynamic from 'next/dynamic'

import { useMenuContext } from '../../mega-menu-provider'

import { TypeformLogoWrapper } from './logo.styles'

const NowYouKnowLogo = dynamic(
  () => import('components/atoms/common/logo/now-you-know-logo')
)

const Logo = (
  props: TLogoProps & {
    shouldUseNowYouKnowLogo?: boolean
  }
) => {
  const { isMenuScrolled } = useMenuContext()

  return (
    <TypeformLogoWrapper>
      {props.shouldUseNowYouKnowLogo ? (
        <NowYouKnowLogo
          data-testid='static-logo'
          {...props}
          hideType={isMenuScrolled}
        />
      ) : (
        <TypeformLogo
          data-testid='static-logo'
          {...props}
          animated
          hideType={isMenuScrolled}
        />
      )}
    </TypeformLogoWrapper>
  )
}

export default Logo
